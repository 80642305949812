<template>
  <div class="body-bullet-container">
    <h3 :style="{color: myColors.main}" class="work-item-subhead">{{subtitle}}</h3>
    <p :style="{color: myColors.foreground}">{{body}}</p>
    <ul type="1" class="bullet-list">
      <li
        :class="{'bullet-li': !isDark, 'dark-bullet-li': isDark}"
        v-for="(item, index) in list"
        :style="{color: myColors.foreground}"
        :key="index"
      >{{item}}</li>
    </ul>
  </div>
</template>
<script>
import { Component, Prop } from "vue-property-decorator";
import Vue, { PropOptions } from "vue";
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";

export default {
  store: store,
  props: {
    title: {
      type: String,
    },
    list: {
      default: Array,
    },
    subtitle: {
      default: "Description",
      type: String,
    },
    body: {
      default: "Body text",
      type: String,
    },
  },

  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
  },
};
</script>
<style scoped>
p {
  margin: 0 0 1rem 0;
}
div {
  margin: 0 0 6rem 0;
}
h3 {
  margin: 0 0 1rem 0;
}
.body-bullet-container {
  margin: 4rem 0 0 0;
}
</style>