<template>
  <div :class="figureClass" @click="setZoomedImage">
    <slot/>
    <p
        class="caption"
        :style="{color: myColors.onBackground, 'text-align': textAlignment, width: captionWidth}"
    >{{ caption }}</p>
  </div>
</template>

<script>
import {store} from "@/store/store.js";
import {colors} from "@/core/colors.js";

export default {
  store: store,
  props: {
    caption: {
      type: String,
    },
    textAlignment: {
      type: String,
      default: "left",
    },
    captionWidth: {
      type: String,
      default: "",
    },
    imageSource: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      theImage: "persona_1.webp",
    };
  },
  computed: {
    imageSrc: function () {
      return "../../assets/" + this.image;
    },
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
    figureClass: function () {
      if (this.imageSource == "") {
        return "figure";
      } else {
        return "figure interact";
      }
    },
  },
  methods: {
    zoomIn() {
      this.$emit("zoomTo", this.imageSource);
    },
    setZoomedImage(image) {
      if (this.imageSource != "") {
        this.$store.commit("setZoomImage", this.imageSource);
      }
    },
  },
};
</script>

<style scoped>
.interact {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.figure {
  margin: 2rem 0;
}

.interact:hover {
  filter: brightness(0.9);
}

@media (max-width: 480px) {
  p {
    margin: 0 0 2rem 2rem;
    text-align: left;
    text-align: left;
    width: calc(100vw - 4rem);
  }
}
</style>