<template>
  <div class="container">
    <div class="video-unit">
      <div
        :style="{
          background: coverBackground,
          opacity: coverOpacity,
        }"
        class="player-cover carousel"
        @click="playVideo('carousel')"
        @mouseenter="animateActive"
        @mouseleave="animateNormal"
      >
        <img
          :src="playIcon"
          :style="{ background: iconOpacity }"
          class="play-icon"
        />
      </div>
      <!-- Cover -->
      <video ref="media" :style="videoStyle" @ended="playing = false">
        <source :src="video" type="video/mp4" />
        <slot />
        <p>Your browser doesn't support HTML5 video.</p>
      </video>
      <!-- Video -->
    </div>
    <div :class="['caption', isDark ? 'caption-dark' : 'caption-light']">
      <p>{{ caption }}</p>
    </div>
  </div>
  <!-- Container mobile -->
</template>

<script>
import CarouselVideo from "@/assets/videos/design_system/card_carousel_mobile.mp4";
import PlayIcon from "@/assets/images/play_icon.webp";
import { colorMixin } from "@/core/mixins/color_mixin.js";
import { store } from "@/store/store";

export default {
  mixins: [colorMixin],
  store: store,
  props: {
    video: {
      type: String,
      required: true,
    },
    videoWidth: {
      type: String,
      default: "20rem",
    },
    videoHeight: {
      type: String,
      default: "20rem",
    },
    coverHeight: {
      type: String,
      default: "",
    },
    isDesktop: {
      type: Boolean,
      default: true,
    },
    videoStyle: {
      type: Object,
    },
    caption: {
      type: String,
    },
  },
  data() {
    return {
      useHTML: true,
      coverBackground: "rgba(83, 82, 80, 0.5)",
      iconOpacity: 0.8,
      coverOpacity: 1,
      playing: false,
      carousel: CarouselVideo,
      playIcon: PlayIcon,
    };
  },
  watch: {
    playing: function (newState, oldState) {
      if (newState == true) {
        this.coverOpacity = 0;
      } else {
        this.coverOpacity = 1;
      }
    },
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
  },
  methods: {
    animateActive: function () {
      this.coverBackground = "rgba(3, 29, 96, 0.5)";
      this.iconOpacity = 1;
    },
    animateNormal: function () {
      this.coverBackground = "rgba(83, 82, 80, 0.5)";
      this.iconOpacity = 0.8;
      console.log("done");
    },
    endVideo: function () {
      this.playing = true;
    },
    playVideo: function (videoRef) {
      this.coverOpacity = 0;
      this.playing = true;
      this.$refs.media.play();
    },
  },
};
</script>

<style lang="css" scoped>
img {
  inline-size: 4rem;
  block-size: 4rem;
}

.container {
  display: grid !important;
  place-items: flex-start;
  place-content: space-around;
  margin: 0 auto;
  width: 100%;
  block-size: fit-content;
}

.player-cover {
  inline-size: 100%;
}

.player-cover {
  background: linear-gradient(
    0deg,
    rgba(83, 82, 80, 0.5),
    rgba(83, 82, 80, 0.5)
  );
  z-index: 10;
  inline-size: 100%;
  block-size: 100%;
  border-radius: 1rem;
  transition: all 0.3s ease-in-out;
  display: grid;
  place-items: center;
  cursor: pointer;
  grid-column: 1 / span 5;
  grid-row: 1 / span 5;
}

.videos {
  width: 56rem;
  display: grid;
  grid-template-columns: 22.875rem 19.5rem;
  background: yellow;
  place-items: center;
  grid-gap: 2rem;
  justify-content: center;
}

video {
  display: grid;
  inline-size: inherit !important;
  grid-column: 1 / span 5;
  grid-row: 1 / span 5;
}

.video-unit {
  display: grid;
  place-items: center;
  place-content: center;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  inline-size: 100%;
  overflow: hidden;
  border-radius: 1rem;
}

.caption {
  font-size: small;
  inline-size: 100%;
  margin-block-start: 1rem;
  text-align: start;
}

.caption-light {
  color: var(--color-gray-100);
}

.caption-dark {
  color: var(--color-gray-25);
}

@media (max-width: 480px) {
  .container {
  }

  video {
    width: calc(100vw - 4rem);
  }

  .player-cover {
    object-fit: contain;
  }

  .caption {
    padding: 0 2rem;
    margin: 2rem 0 0 0;
  }
}
</style>
