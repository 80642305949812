<template>
  <div v-if="!boldFirst" class="list-container">
    <h1
      v-if="title != undefined"
      :style="{ color: myColors.main }"
      class="header-bold"
    >
      {{ title }}
    </h1>
    <h3
      v-show="subtitle"
      :style="{ color: myColors.main }"
      class="header-3 header-bold"
    >
      {{ subtitle }}
    </h3>
    <p
      v-show="body"
      :class="['intro', isDark ? 'body-dark' : 'body-light']"
      v-html="body"
    />
    <ol class="numbered" type="1">
      <li
        v-for="(item, index) in list"
        :key="index"
        :class="[
          isDark ? 'dark-primary-li' : 'primary-li',
          { 'numbered-list': isNumbered },
          { 'marker-dark': isNumbered && isDark },
          { 'marker-light': isNumbered && !isDark },
        ]"
        :style="{ color: myColors.foreground }"
        v-html="item"
      />
    </ol>
  </div>
  <div v-else class="list-container">
    <h1 v-if="title" :style="{ color: myColors.main }" class="work-item-header">
      {{ title }}
    </h1>
    <h3 :style="{ color: myColors.main }" class="work-item-subhead">
      {{ subtitle }}
    </h3>
    <ol class="numbered" type="1">
      <li
        v-for="(item, index) in list"
        :key="index"
        :class="[isDark ? 'primary-li' : 'dark-primary-li']"
        :style="{ color: myColors.foreground }"
      >
        <span class="first">{{ item.split(" ")[0] + " " }}</span>
        <span>{{ item.substr(item.indexOf(" ") + 1) }}</span>
      </li>
    </ol>
  </div>
</template>
<script>
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";

export default {
  store: store,
  props: {
    title: {
      type: String,
    },
    isNumbered: {
      type: Boolean,
      default: true,
    },
    body: String,
    boldFirst: {
      type: Boolean,
      default: false,
    },
    list: {
      default: Array,
    },
    subtitle: {
      type: String,
    },
  },

  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
  },
};
</script>
<style scoped>
.intro {
  margin-block-end: 1rem;
}

.list-container {
  display: grid;
  place-content: flex-start;
  place-items: flex-start;
  inline-size: 100%;
}

.first {
  font-weight: bold;
}

h3 {
  margin: 0 0 1rem 0;
}

h1 {
  margin-block: 3rem;
}
</style>
